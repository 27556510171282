<template>
  <div>
    <section>
      <v-snackbar v-model="snackbar.model" timeout="3000">{{
        snackbar.text
      }}</v-snackbar>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-form
                v-model="form3.isValid"
                @submit.prevent="updateLeaveConfig"
              >
                <Subheader
                  icon="mdi-run-fast"
                  title="Abwesenheiten"
                ></Subheader>
                <div class="mt-7">
                  <v-card :loading="loading" :disabled="form3.isLoading">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="availabilityConfig.leaves.notifyPersons"
                              :items="membershipsListFiltered"
                              chips
                              small-chips
                              outlined
                              deletable-chips
                              label="Zusätzlich zu benachrichtigende Personen"
                              item-text="displayName"
                              item-value="uid"
                              multiple
                              clearable
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <notifications-forecast-preview-card
                              infoText="Es wird versucht, berechtigte
          Personen zu benachrichtigen."
                              :notifyPersonsUids="
                                availabilityConfig.leaves.notifyPersons
                              "
                              notificationTriggerId="availability_leaveCreated"
                            ></notifications-forecast-preview-card>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- :disabled="!form3.isValid" -->
                        <v-btn
                          depressed
                          color="success"
                          :loading="form3.isLoading"
                          @click="updateLeaveConfig()"
                        >
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import NotificationsForecastPreviewCard from "@/components/_systemwide/NotificationsForecastPreviewCard.vue";
// import { db } from "@/firebase";
import { AVAILABILITY, ADMIN } from "@/store/modules.js";
import {
  UPDATE_AVAILABILITY_CONFIG,
  GET_USER_LIST,
} from "@/store/action-types.js";

export default {
  name: "availability-config-tab-general",
  components: {
    Subheader,
    NotificationsForecastPreviewCard,
  },
  data() {
    return {
      loading: true,
      snackbar: {
        model: false,
        text: "",
      },
      form1: {
        isValid: false,
        isLoading: false,
      },
      form3: {
        isValid: false,
        isLoading: false,
      },

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        min: (value) =>
          /^[+]?\d+([.]\d+)?$/.test(value) || "Muss positive Zahl sein.",
      },
    };
  },
  computed: {
    availabilityConfig() {
      return this.$store.state.organization.activeOrganization.config
        .availability;
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    membershipsListFiltered() {
      const input = this.memberships;
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.title] === "undefined") a[c.group.title] = [];
          a[c.group.title] = [...a[c.group.title], c];
          return a;
        }, {});
      };
      const step1 = groupItems(input);
      const insertHeaders = (obj) => {
        return Object.entries(obj).reduce((a, [key, vals], i, d) => {
          let r = [
            {
              header: key,
            },
            ...vals,
          ];
          // this is needed to have the exact same array
          // that you presented as desirable output:
          // no divider at the last place
          if (i < d.length - 1) {
            r = [
              ...r,
              {
                divider: true,
              },
            ];
          }
          a = [...a, ...r];
          return a;
        }, []);
      };
      const step2 = insertHeaders(step1);
      const step3 = step2
        .filter(
          // FIXME: temporary fix, to be watched
          (item) =>
            item.header || (item.user && item.user.displayName && item.user.uid)
        )
        .map((item) => {
          if (item.header) {
            // Skip headers and dividers
            return item;
          } else if (item.user && item.user.displayName && item.user.uid) {
            // Extract displayName and uid from membership object
            return {
              displayName: item.user.displayName || "",
              uid: item.user.uid,
            };
          } else {
            return {
              displayName: "",
              uid: "",
            };
          }
        });

      return step3;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store
        .dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "active",
          },
        })
        .then(() => {
          this.loading = false;
        });
    },

    updateLeaveConfig() {
      if (this.form3.isValid) {
        this.form3.isLoading = true;
        this.$store
          .dispatch(`${AVAILABILITY}${UPDATE_AVAILABILITY_CONFIG}`, {
            organizationId: this.$route.params.organizationId,
            type: "onlyLeaveConfig",
            leaves: this.availabilityConfig.leaves,
          })
          .then(() => {
            this.form3.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Einstellungen erfolgreich aktualisiert.";
          })
          .catch((error) => {
            console.log(error);
            this.form3.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = error.message;
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
