export const importableRequestTypes = [
  {
    title: "Verfügbarkeitsabfrage",
    shortTitle: "VF",
    description: "Abfrage von Verfügbarkeiten bspw. für längere Zeiträume",
    color: "#CDDC39",
    sortKey: 1,
    isImported: true,
    importVersion: 1,
    importId: "availabilityRequest",
  },
  {
    title: "Terminabfrage",
    shortTitle: "VF",
    description: "Abfrage von verschiedenen Terminen für eine Veranstaltung",
    color: "#795548",
    sortKey: 2,
    isImported: true,
    importVersion: 1,
    importId: "dateRequest",
  },
  {
    title: "Schichtenabfrage",
    shortTitle: "VF",
    description:
      "Abfrage von verschiedenen Schichten im Rahmen von längeren Ereignissen",
    color: "#9C27B0",
    sortKey: 3,
    isImported: true,
    importVersion: 1,
    importId: "shiftRequest",
  },
  {
    title: "Einzelgespräche",
    shortTitle: "EG",
    description: "Abfrage von Verfügbarkeiten bspw. für längere Zeiträume",
    color: "#009688",
    sortKey: 4,
    isImported: true,
    importVersion: 1,
    importId: "meetingRequest",
  },
];
