<template>
  <div>
    <NavigationBar
      titel="Konfiguration"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>

    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "availability-config",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `config/general`,
      tabs: [{ id: 1, name: "Allgemein", route: `general` }],
    };
  },
};
</script>
