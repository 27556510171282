<template>
  <div>
    <v-card outlined>
      <v-card-subtitle v-if="infoText"
        ><v-icon left small class="mr-2">mdi-information</v-icon
        >{{ infoText }}</v-card-subtitle
      >
      <v-divider v-if="infoText"></v-divider>
      <v-card-actions v-if="!notificationForecast.isVisible">
        <v-btn
          depressed
          :loading="notificationForecast.isLoading"
          @click="getNotificationForecast()"
          ><v-icon left>mdi-checkbox-blank-badge</v-icon>Wer wird
          benachrichtigt?</v-btn
        >
      </v-card-actions>
      <v-card-subtitle v-if="notificationForecast.isVisible"
        >Eine Benachrichtigung kann aufgrund der persönlichen
        Benachrichtigungseinstellungen per E-Mail an
        <strong
          >{{ forecastResult.length }} von
          {{ membershipsWithForecast.length }} Personen</strong
        >
        gesendet werden:</v-card-subtitle
      >
      <v-card-text v-if="notificationForecast.isVisible">
        <v-chip
          v-for="membership in membershipsWithForecast"
          :key="membership.user.uid"
          small
          class="mr-2 my-1"
          :color="
            membership.notificationTriggerIsEnabled
              ? 'green lighten-4'
              : 'red lighten-4'
          "
          :class="
            membership.notificationTriggerIsEnabled
              ? 'green--text text--darken-4 font-weight-medium'
              : 'red--text text--darken-4 font-weight-medium'
          "
          ><v-icon left small>{{
            membership.notificationTriggerIsEnabled
              ? "mdi-check-circle"
              : "mdi-close-circle"
          }}</v-icon
          >{{ membership.user.displayName }}</v-chip
        ></v-card-text
      >
    </v-card>
  </div>
</template>
<script>
import { functions } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_LIST } from "@/store/action-types.js";
export default {
  name: "notifications-forecast-preview-card",
  props: {
    infoText: {
      type: String,
      required: false,
      default: "",
    },
    notifyPersonsUids: {
      required: true,
      default: [],
    },
    notificationTriggerId: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      notificationForecast: {
        isLoading: false,
        isVisible: false,
      },
      forecastResult: [],
      membershipsWithForecast: [],
    };
  },
  created() {},
  computed: {
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
  },
  methods: {
    getNotificationForecast() {
      this.notificationForecast.isLoading = true;
      this.forecastLoading = true;
      const callFunction = functions.httpsCallable(
        "notification-getNotificatationForecast"
      );
      this.$store
        .dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "active",
          },
        })
        .then(() => {
          return callFunction({
            organizationId: this.$route.params.organizationId,
            triggerId: this.notificationTriggerId,
            uids: this.notifyPersonsUids,
          });
        })
        .then((result) => {
          this.forecastResult = result.data;
          console.log(result);
          this.membershipsWithForecast = this.memberships
            .filter((membership) =>
              this.notifyPersonsUids.includes(membership.user.uid)
            )
            .map((membership) => {
              const membershipWithForecast = {
                ...membership,
                notificationTriggerIsEnabled: this.forecastResult.includes(
                  membership.user.uid
                ),
              };
              return membershipWithForecast;
            });
          this.notificationForecast.isLoading = false;
          this.notificationForecast.isVisible = true;
        })
        .catch((error) => {
          // handle error
          console.error(error.errorMsg);
          console.error(error);
          this.errorMsg = error.message;
          this.loading = false;
        });
    },
  },
};
</script>
